import api from './api';

class TeachersService {
  get(id) {
    return api.get(`/teachers/${id}`).then((res) => res.data);
  }

  find(params) {
    return api.get('/teachers', { params }).then((res) => res.data);
  }

  create(data) {
    return api.post(`/teachers`, data).then((res) => res.data);
  }

  update(id, data) {
    return api.patch(`/teachers/${id}`, data).then((res) => res.data);
  }

  delete(id) {
    return api.delete(`/teachers/${id}`).then((res) => res.data);
  }
}

export default new TeachersService();
