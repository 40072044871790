import api from './api';

class StudentClassTeacherService {
  create(data) {
    return api.post(`/student-class-teachers`, data).then((res) => res.data);
  }

  findAllByStudentClass(id) {
    return api.get(`/student-class-teachers/student-class/${id}`).then((res) => res.data);
  }

  findAllByTeacher(id) {
    return api.get(`/student-class-teachers/teacher/${id}`).then((res) => res.data);
  }

  deleteAllByStudentClass(id) {
    return api.delete(`/student-class-teachers/student-class/${id}`).then((res) => res.data);
  }

  deleteAllByTeacher(id) {
    return api.delete(`/student-class-teachers/teacher/${id}`).then((res) => res.data);
  }
}

export default new StudentClassTeacherService();
