<script>
import StudentClassTeacherService from '@/service/student-class-teachers.service';
import StudentClassService from '@/service/student-classes.service';
import TeachersService from '@/service/teachers.service';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import cloneDeep from 'lodash/cloneDeep';

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: 'modal-register-student-class',

  data() {
    return {
      dialog: false,
      loading: false,
      loadingSave: false,
      form: {},
      teachers: [],
      selectedTeachers: [],
    };
  },

  created() {
    this.$on('create', this.create);
    this.$on('edit', this.edit);
  },

  methods: {
    create() {
      this.form = {};
      this.selectedTeachers = [];
      this.fetchAllTeachers();
      this.dialog = true;
    },

    edit(id) {
      this.fetch(id);
      this.fetchAllTeachers();
      this.fetchSelectedTeachers(id);
      this.dialog = true;
    },

    async fetch(id) {
      this.loading = true;

      try {
        const studentClass = await StudentClassService.get(id);
        this.form = studentClass;
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },

    async fetchAllTeachers() {
      this.loading = true;

      try {
        const teachers = await TeachersService.find({ where: { deletedAt: null } });
        this.teachers = teachers.data.map((item) => {
          return {
            value: item.id,
            text: `${item.name}${item.course ? ' - ' + item.course : ''}`,
          };
        });
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },

    async fetchSelectedTeachers(id) {
      this.loading = true;

      try {
        const slctTeachers = await StudentClassTeacherService.findAllByStudentClass(id);
        this.selectedTeachers = slctTeachers.map((item) => parseInt(item.teacherId));
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },

    async save() {
      this.loadingSave = true;
      try {
        const form = cloneDeep(this.form);
        if (form.id) {
          const id = this.form.id;
          delete form.id;
          await StudentClassService.update(id, form);
          await StudentClassTeacherService.deleteAllByStudentClass(id);
          this.selectedTeachers.forEach(
            async (item) => await StudentClassTeacherService.create({ studentClassId: id, teacherId: item }),
          );
        } else {
          const studentClassId = (await StudentClassService.create(form)).id;
          this.selectedTeachers.forEach(
            async (item) => await StudentClassTeacherService.create({ studentClassId, teacherId: item }),
          );
        }
        this.dialog = false;
        this.$swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Salvo com sucesso!',
          timer: 2000,
          showConfirmButton: false,
        });
        this.$emit('success');
      } catch (e) {
        console.error(e);
      }
      this.loadingSave = false;
    },

    handleCancel() {
      this.dialog = false;
      this.form = {};
    },
  },
};
</script>

<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <v-card :loading="loading">
      <v-card-title>
        <span class="text-h5">{{ !form.id ? 'Cadastro' : 'Edição' }} de turma</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12">
              <v-row>
                <v-col cols="12">
                  <v-row v-if="form.id">
                    <v-col cols="12">
                      <v-text-field
                        v-model="form.id"
                        label="ID"
                        color="white"
                        hide-details="auto"
                        disabled
                        outlined
                        dense
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="9">
                      <v-text-field
                        v-model="form.name"
                        label="Nome"
                        color="white"
                        hide-details="auto"
                        outlined
                        persistent-placeholder
                        dense
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-select
                        v-model="form.session"
                        :items="[
                          { text: 'Matutino', value: 'MORNING' },
                          { text: 'Vespertino', value: 'EVENING' },
                          { text: 'Noturno', value: 'NOCTURNAL' },
                          { text: 'Integral', value: 'INTEGRAL' },
                        ]"
                        label="Período"
                        color="white"
                        hide-details="auto"
                        outlined
                        persistent-placeholder
                        dense
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        v-model="selectedTeachers"
                        :items="teachers"
                        label="Professores responsáveis"
                        color="white"
                        hide-details="auto"
                        multiple
                        outlined
                        persistent-placeholder
                        dense
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        v-model="form.ageGroup"
                        :items="[
                          { text: '1-4 anos: creche', value: 'NURSERY' },
                          { text: '4-5 anos : Infantil (pré 1 e pré 2)', value: 'CHILDISH' },
                          { text: '6-14 anos:  Fundamental (1° ao 9° ano)', value: 'FUNDAMENTAL' },
                          { text: '15-18 anos: Médio (1-3 ano ensino médio)', value: 'MEDIUM' },
                        ]"
                        label="Período"
                        color="white"
                        hide-details="auto"
                        outlined
                        persistent-placeholder
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="handleCancel"> Cancelar </v-btn>
        <v-btn color="success" :loading="loadingSave" @click="save"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
