var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(!_vm.form.id ? 'Cadastro' : 'Edição')+" de turma")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.form.id)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"ID","color":"white","hide-details":"auto","disabled":"","outlined":"","dense":""},model:{value:(_vm.form.id),callback:function ($$v) {_vm.$set(_vm.form, "id", $$v)},expression:"form.id"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"label":"Nome","color":"white","hide-details":"auto","outlined":"","persistent-placeholder":"","dense":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":[
                        { text: 'Matutino', value: 'MORNING' },
                        { text: 'Vespertino', value: 'EVENING' },
                        { text: 'Noturno', value: 'NOCTURNAL' },
                        { text: 'Integral', value: 'INTEGRAL' },
                      ],"label":"Período","color":"white","hide-details":"auto","outlined":"","persistent-placeholder":"","dense":""},model:{value:(_vm.form.session),callback:function ($$v) {_vm.$set(_vm.form, "session", $$v)},expression:"form.session"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.teachers,"label":"Professores responsáveis","color":"white","hide-details":"auto","multiple":"","outlined":"","persistent-placeholder":"","dense":""},model:{value:(_vm.selectedTeachers),callback:function ($$v) {_vm.selectedTeachers=$$v},expression:"selectedTeachers"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":[
                        { text: '1-4 anos: creche', value: 'NURSERY' },
                        { text: '4-5 anos : Infantil (pré 1 e pré 2)', value: 'CHILDISH' },
                        { text: '6-14 anos:  Fundamental (1° ao 9° ano)', value: 'FUNDAMENTAL' },
                        { text: '15-18 anos: Médio (1-3 ano ensino médio)', value: 'MEDIUM' },
                      ],"label":"Período","color":"white","hide-details":"auto","outlined":"","persistent-placeholder":"","dense":""},model:{value:(_vm.form.ageGroup),callback:function ($$v) {_vm.$set(_vm.form, "ageGroup", $$v)},expression:"form.ageGroup"}})],1)],1)],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.handleCancel}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"success","loading":_vm.loadingSave},on:{"click":_vm.save}},[_vm._v(" Salvar ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }